<template>
  <div class="container">
    <el-form :model="searchForm" ref="searchForm" label-width="70px" class="searchForm">

      <el-row>
        <el-col :span='6'>
          <el-form-item label="项目名称">
            <!-- <selects @handleChange="handleSelect($event,0)" :isAllow='true' ref="projectNameRef"
              :options="projectName">
            </selects> -->
            <el-select v-model="searchForm.projectName" filterable default-first-option
              :placeholder="'请选择'" clearable class="default">
              <el-option v-for="item in projectName" :key="item.id" :value="item.label">
                <span style="float: left">{{ item.label }}</span>
                <span style="float: right; color: #8492a6;">ID:{{ item.id }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span='6'>
          <el-form-item label="接口名称">
            <selects @handleChange="handleSelect($event,1)" :isAllow='true' ref="apiNameRef"
              :options="apiName">
            </selects>
          </el-form-item>
        </el-col>
        <el-col :span='6'>
          <el-form-item label="模块">
            <selects @handleChange="handleSelect($event,2)" :isAllow='true' ref="UIRef"
              :options="apiName">
            </selects>
          </el-form-item>
        </el-col>
        <el-col :span='6'>
          <el-form-item label="前端">
            <selects @handleChange="handleSelect($event,3)" :isAllow='true' ref="weberRef"
              :options="proManager">
            </selects>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span='6'>
          <el-form-item label="后端">
            <selects @handleChange="handleSelect($event,4)" :isAllow='true' ref="devBackRef"
              :options="proManager">
            </selects>
          </el-form-item>
        </el-col>
        <el-col :span='6'>
          <el-form-item label="版本号">
            <el-input type="text" v-model="searchForm.proVersion" class="default"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span='6'>
          <el-form-item label="状态">
            <selects @handleChange="handleSelect($event,5)" ref="statusRef" :options="status">
            </selects>
          </el-form-item>
        </el-col>
        <el-col :span='6'>
          <el-button class="formBtn" @click="openNewApi(id=-1)">新建接口</el-button>
          <el-button class="formBtn btn-reset" @click="resetForm">重置</el-button>
          <el-button class="formBtn" @click="search(type=-1)" :loading="Searching">
            {{Searching ?  '搜索中...':'搜索'}}</el-button>
        </el-col>
      </el-row>

    </el-form>

    <createApi @createApi="handleNewProject" :Member="proManager" ref="createApi"></createApi>

    <!-- 项目列表 -->
    <div>
      <el-table :data="apiList" stripe v-loading="Searching" :row-style="{height:'64px',}"
        :cell-style="{'text-align':'center'}"
        :header-cell-style="{'border-top':'solid 1px #E3E3E3','background': '#F5F5F5','text-align':'center'}"
        style="width: 100%">
        <el-table-column prop="id" label="ID" width="70" align="center">
        </el-table-column>
        <el-table-column prop="projectName" label="项目名称" min-width="180">
        </el-table-column>
        <el-table-column prop="proVersion" label="版本号" min-width="120">
        </el-table-column>
        <el-table-column prop="apiName" label="接口名称" min-width="120">
        </el-table-column>
        <el-table-column prop="proDevFront" label="前端" min-width="180">
        </el-table-column>
        <el-table-column prop="proDevBack" label="后端" min-width="180">
        </el-table-column>
        <el-table-column prop="apiModule" label="模块" min-width="180">
        </el-table-column>
        <el-table-column prop="apiDesc" label="描述" min-width="180">
          <template slot-scope="scope">
            <el-tooltip :content="scope.row.apiDesc" placement="bottom">
              <!-- <p class="longInfo">{{scope.row.apiDesc}}</p> -->
              <el-link class="longInfo" type="primary" :href='scope.row.pageUrl'
                icon="el-icon-link">
                {{scope.row.apiDesc}}</el-link>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" min-width="120">
          <template slot-scope="scope">
            <span style="color: #3285EA">
              {{(scope.row.status==0) ? '未定义' : ''}}
              {{(scope.row.status==1) ? '已定义' : ''}}
              {{(scope.row.status==2) ? '已实现' : ''}}
              {{(scope.row.status==3) ? '已调通' : ''}}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" min-width="150">
          <template slot-scope="scope">
            <el-button type="text" @click="openNewApi(scope.row.id,type=0)">编辑</el-button>
            <el-button type="text" @click="openNewApi(scope.row.id,type=1)" class="delectBtn">复制
            </el-button>
            <el-button type="text" @click="deleteApi(scope.row.id)" class="delectBtn">删除</el-button>

          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页器 -->
    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="1" :page-sizes="[5, 10, 15]" :page-size="100"
        layout="total, sizes, prev, pager, next, jumper" :total="dataTotal">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import {
  POST_ADDEDITAPI,
  POST_QUERYAPILIST,
  GET_DELEAPIBYID,
} from "../request/api";
import { selects, createApi } from "./parts";
export default {
  data() {
    return {
      dataTotal: 0,
      Searching: false, //搜索中
      searchForm: {
        projectName: "", //项目名称
        apiName: "", //接口名称
        proDevFront: [], //前端工程师
        proDevBack: [], //后端工程师
        apiModule: "", //模块名称
        proVersion: "", //版本号
        status: "", //状态

        pageNumber: 1,
        pageSize: 5,
      },
      //   项目名称
      projectName: [],
      //   接口名称
      apiName: [
        { value: "测试接口", label: "测试接口" },
        { value: "正式接口", label: "正式接口" },
        { value: "开发接口", label: "开发接口" },
      ],
      proManager: [],
      //状态
      status: [
        { value: "0", label: "未定义" },
        { value: "1", label: "已定义" },
        { value: "2", label: "已实现" },
        { value: "3", label: "已调通" },
      ],

      apiList: [],
    };
  },
  created() {
    this.searchForm.projectName = this.$store.state.currentProject.label;
    this.projectName = this.$store.state.projectName;
    this.proManager = this.$store.state.Member;
    this.search();
  },
  methods: {
    // 重置搜索条件
    resetForm() {
      this.searchForm.projectName = "";
      this.searchForm.apiName = "";
      this.searchForm.proDevFront = [];
      this.searchForm.proDevBack = [];
      this.searchForm.apiModule = "";
      this.searchForm.proVersion = "";
      this.searchForm.status = "";
      let refList = [
        "apiNameRef",
        "statusRef",
        "weberRef",
        "UIRef",
        "devBackRef",
      ];
      refList.forEach((element) => {
        this.$refs[element].resetValue();
      });
      this.search();
    },
    // 下拉框选择赋值
    handleSelect(val, type) {
      if (type == 1) {
        this.searchForm.apiName = val;
      } else if (type == 2) {
        this.searchForm.apiModule = val;
      } else if (type == 3) {
        this.searchForm.proDevFront.push(val);
        if (this.searchForm.proDevFront.length > 1) {
          this.searchForm.proDevFront.splice(0, 1);
        }
      } else if (type == 4) {
        this.searchForm.proDevBack.push(val);
        if (this.searchForm.proDevBack.length > 1) {
          this.searchForm.proDevBack.splice(0, 1);
        }
      } else {
        this.searchForm.status = val;
      }
    },
    // 打开新建项目对话框
    openNewApi(id, type) {
      this.$refs.createApi.openNewApi(id, type);
    },
    // 新建项目
    handleNewProject(val) {
      POST_ADDEDITAPI(val)
        .then(({ data: res }) => {
          if (res.resCode == 1) {
            this.$notify({ title: "接口创建成功", type: "success" });
          } else {
            this.$notify.error({
              title: "接口创建失败",
            });
          }
          setTimeout(() => {
            this.search();
            this.$refs.createApi.handleClose();
          }, 400);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查询搜索
    search(type) {
      if (type == -1) {
        this.searchForm.pageNumber = 1;
      }
      this.Searching = true;
      const waitsearch = setTimeout(() => {
        this.Searching = false;
      }, 600);
      POST_QUERYAPILIST(this.searchForm)
        .then(({ data: res }) => {
          if (res.resCode == 1) {
            this.dataTotal = res.data.total;
            this.apiList = res.data.list;
          }
          waitsearch;
        })
        .catch((err) => {
          console.log(err);
          waitsearch;
        });
    },
    //删除项目
    deleteApi(id) {
      this.$confirm("此操作将永久删除该接口, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          GET_DELEAPIBYID({ id })
            .then(({ data: res }) => {
              if (res.resCode == 1) {
                this.$notify({
                  title: "成功",
                  message: "接口删除成功",
                  type: "success",
                });
                this.search();
              } else {
                this.$notify.error({
                  title: "失败",
                  message: res.resMsg,
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
    // 分页器
    handleSizeChange(val) {
      this.searchForm.pageSize = val;
      this.search();
    },
    handleCurrentChange(val) {
      this.searchForm.pageNumber = val;
      this.search();
    },
  },
  computed: {
    name() {
      return this.$store.state.currentProject;
    },
  },
  watch: {
    name(newValue) {
      this.searchForm.projectName = newValue.label;
    },
  },
  components: {
    selects,
    createApi,
  },
};
</script>

<style lang="less" scoped>
.default {
  width: 200px;

  /deep/ .el-input__inner {
    height: 36px;
    line-height: 36px;
    border-radius: 0;
  }
}

// 搜索按钮
.formBtn {
  height: 36px;
  background: #3285ea;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 36px;
  padding: 0;
  border-radius: 0;
  padding: 0 22px;
}
.btn-reset {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.delectBtn {
  margin-left: 16px;
}
// 外链描述等内容
.longInfo {
  // width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
